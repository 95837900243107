/* tag list */
.Tag .ui.compact.segment.tags {
    display: block;
    max-width: 300px;
    float: right;
}

/* gallery */

.Tag .react-photo-gallery--gallery img {
  object-fit: cover;
}

/* modals */

.Tag.ui.modal>.content>.description.photogallery {
  flex: auto;
  margin-top: 0;
}

.Tag.ui.modal>.content.zoomImage>.image {
  min-width: 400px;
}

.Tag.ui.modal>.content.zoomImage>.description>.tagList {
/*  float: right;
  max-width: 300px;*/
}

/* modals */
/*
.Tag.ui.modal {
  height: max-content;
  bottom: auto;
  margin: 3rem auto;
  max-width: 785px;
}

.Tag.ui.modal>.content>.description.photogallery {
  flex: auto;
  margin-top: 0;
}

.Tag.ui.modal>.content.zoomImage img {
  height: 400px;
  object-fit: cover;
  width: 400px;
  padding: 5px;
}

.Tag .ui.container.zoomImageTags {
  width: auto;
}
*/

/*
.Tag .row.tagList {
  padding: 0;
  margin: 0;
}

*/
/*
.ui.container.thumbs {
  display: flex;
}
*/
/*

.ui.right.rail {
  padding:0 0 0 0rem;
  margin: 10px 0px 0px 30px;
  width: 250px;
}

.ui.segment.scroll {

}

.ui.checkbox.gridCheck {
  margin-top: 5px;
  margin-bottom: 0px;
}

.ui[class*="vertically divided"].grid>.column:not(.row),
.ui[class*="vertically divided"].grid>.row>.column {
 margin-top:0;
 margin-bottom:0;
}

.ui.grid {
 margin-top:.3rem;
}

.ui.grid>.row {
 padding-top:3px;
 padding-bottom:3px;
}

.ui.menu {
 min-height:0;
}

.ui.menu .item {
 padding:5px 1.14285714em;
}

.ui.segment.tags {
  max-width: 300px;
  float: right;
}

.row {
  margin-left: 0px;
}

.modal.zoomModal {
  width: 650px;
  height: inherit;
  margin: 20rem auto;
}

.modal.zoomModal img {
  height: 300px;
  object-fit: cover;
  width: 400px;
}

.image > div {
 float: inline-start;
}

.ui.modal>.content>.description {
 margin-left: 1rem;
 float: inline-start;
}

.ui.container.tagModal {
  width: 800px;
}
*/

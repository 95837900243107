/* infinite scroll */
.Select .ui.container.thumbs {
  display: contents;
}

.Select .LazyLoad.thumb img {
  height: 175px;
  object-fit: cover;
  width: 175px;
}

.Select .react-photo-gallery--gallery img {
  object-fit: cover;
}
  
.Select .ui.segment.infiniteScroll {
  display: flex;
  flex-wrap: wrap;
  margin-top: 0;
}

.Select .ui.loader.imgLoader {
  height: 175px;
  object-fit: cover;
  width: 175px;
  padding: 5px;
  transform: translateY(20%);
}

.Select .ui.image.myPlaceholder {
  height: 175px;
  width: 175px;
  margin: 5px;

}

/* other */
.Select .dropzone {
  min-height: 50px;
  text-align: center;
}

.Select .ui.thumbs img {
  height: 80px;
}

.Select i.huge.icon {
  font-size: 6.4em;
}

.Select .thumbs {
  display: flex;
  flex-wrap: wrap;
}
/*
.Select .thumb {
  margin: 5px;
}
*/
.Select .thumb img {
  height: 110px;
  object-fit: cover;
  width: 110px;
}

.Select .thumb p {
  margin-top: 0;
  white-space: nowrap;
  overflow: hidden;
  font-size: 14px;
  text-overflow: ellipsis;
}

.Select .thumb a {
  text-decoration: none;
  color: inherit;
}

.Select .loader {
  clear: both;
}

.Select .img-loading {
   opacity: 0;
   width: 100%;
   height: auto;
}

.Select .img-loaded {
   position: relative;
   opacity: 1;
}

.App .row.header {
  background-color: #05e;
  color: #000;
}

.ui.header .sub.header {
  color: #eee;
}

.App .row.footer {
  background-color: #05e;
  color: #eee;
}

.App .ui.dropdown.accountIcon {
  margin: 8px 8px 8px 12px;
}

.row.header a, .row.footer a {
  color: #eee;
}

.row.header a:hover, .row.header a:active, .row.header a:focus,
.row.footer a:hover, .row.footer a:active, .row.footer a:focus {
  color: #111;
}

.ui.tag.label {
  margin-top: 2px;
  margin-bottom: 2px;
  background-color: #fa0;
  color: #111;
}

.ui.tag.label:before {
 width:1.65em;
 height:1.65em;
 border-radius: 0.285714rem;
}

a.ui.label:hover {
  background-color: #05e;
  color: #eee;
}

.ui.button {
  background-color: #09e;
  color: #111;
}

.ui.button:hover, .ui.button:focus, .ui.button:active {
  background-color: #05e;
  color: #eee;
}
